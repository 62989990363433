import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@myproject/guards/auth.guard';
import { HomeComponent } from './home.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [authGuard],
    children: [
        //{ path: 'dashboard', loadChildren: () => import('@components/admin/admin.module').then(m => m.AdminModule), canActivate: [RoleGuard] },
        { path: 'new-orders', loadChildren: () => import('@components/new-orders/new-orders.module').then(m => m.NewOrdersModule) },
        { path: 'return-orders', loadChildren: () => import('@components/return-orders/return-orders.module').then(m => m.ReturnOrdersModule) },
        { path: 'return-status', loadChildren: () => import('@components/return-status/return-status.module').then(m => m.ReturnStatusModule) },
        { path: 'inventory', loadChildren: () => import('@components/inventory/inventory.module').then(m => m.InventoryModule) },
        { path: 'order-history', loadChildren: () => import('@components/order-history/order-history.module').then(m => m.OrderHistoryModule) },
        { path: '', redirectTo: 'new-orders', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
