import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserData } from '@custom-types/types';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthService {

    private tokenKey = 'authToken';
    private path = '/'

    constructor(
        private router: Router,
        private apiService: ApiService,
        private cookieService: CookieService
    ) {}

    public addTokenToStorage(token: string): void {
        this.cookieService.set(this.tokenKey, token, undefined, this.path, '', true, 'Lax');
        //console.log(`cookie is set: ${ this.cookieService.get(this.tokenKey)}`);
    }
    
    public login(username: string, password: string): Observable<UserData> {
        return this.apiService.login(username, password);
    }
    
    public logout(): void {
        this.cookieService.delete(this.tokenKey, this.path);
        this.router.navigate(['/login']);
    }
    
    public isLoggedIn(): boolean {
        const token = this.getToken();
        return !!token;
    }

    public getToken(): string {
        return this.cookieService.get(this.tokenKey) || '';
    }
}
