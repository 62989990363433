<!-- sidebar.component.html -->
<div class="d-flex" id="wrapper">
  <!-- Sidebar -->
  <div class="bg-light border-end" id="sidebar">
    <div class="sidebar-header">
      <img src="assets/danlawlogo.png" alt="Sidebar Header" class="sidebar-header-img">
    </div>
    <div class="list-group list-group-flush">
      <a routerLink="/home/inventory" routerLinkActive="active" class="list-group-item list-group-item-action">
        <i class="fa fa-cube sidebar-icon" aria-hidden="true"></i>
        <span class="sidebar-text">Inventory</span>
      </a>
      <a routerLink="/home/order-history" routerLinkActive="active" class="list-group-item list-group-item-action">
        <i class="fa fa-history sidebar-icon" aria-hidden="true"></i>
        <span class="sidebar-text">Order History</span>
      </a>
      <a routerLink="/home/new-orders" routerLinkActive="active" class="list-group-item list-group-item-action">
        <i class="fa fa-plus sidebar-icon" aria-hidden="true"></i>
        <span class="sidebar-text">New Orders</span>
      </a>
      <a routerLink="/home/return-orders" routerLinkActive="active" class="list-group-item list-group-item-action">
        <i class="fa fa-undo sidebar-icon" aria-hidden="true"></i>
        <span class="sidebar-text">Return Orders</span>
      </a>
      <a routerLink="/home/return-status" routerLinkActive="active" class="list-group-item list-group-item-action">
        <i class="fa fa-undo sidebar-icon" aria-hidden="true"></i>
        <span class="sidebar-text">RMA Status</span>
      </a>
    </div>
    <!-- User Profile Section -->
    <div class="sidebar-profile">
      <div class="profile-info">
        <img src="assets/defaultUserLogo.png" alt="User Profile Picture" class="profile-picture">
        <span class="profile-name">Danlaw Logistics</span>
      </div>
      <button class="btn btn-outline-danger btn-sm logout-button" (click)="logout()">Logout</button>
    </div>
  </div>
  <!-- Page Content -->
  <div id="page-content-wrapper">
    <div class="container-fluid" style="padding: 0rem;">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
