import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserData } from '@myproject/types/types';
import { AuthService } from '@services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertComponent } from '@shared/alert/alert.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  @ViewChild(AlertComponent) private alertComponent!: AlertComponent;
  
  loginForm: FormGroup;
  loggingIn: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      this.loggingIn = true;
      const { username, password } = this.loginForm.value;

      this.authService.login(username, password).subscribe({
        next: (data: UserData) => {
          this.loggingIn = false;
          this.authService.addTokenToStorage(data.accessToken);
          this.router.navigate(['/home']);

        },
        error: (error: HttpErrorResponse) => {
          this.loggingIn = false;
          this.alertComponent.showAlert(error?.error?.message || "There was an error logging in", 'danger');
        }
      });
    }
  }
}
