import { HttpInterceptorFn, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@services/auth.service';
import { environment } from '@environments/env';
import { inject } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const authToken = authService.getToken();
  const isApiUrl = req.url.startsWith(environment.apiUrl);

  if (isApiUrl && authToken) {
    const clonedRequest = req.clone({
      setHeaders: { Authorization: `Bearer ${authToken}` }
    });

    return next(clonedRequest).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          authService.logout();
        }
        return throwError(() => error);
      })
    );
  }

  return next(req).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        authService.logout();
      }
      return throwError(() => error);
    })
  );
};