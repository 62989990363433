<div class="login-background">
  <div class="login-container">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
      <h2>Login</h2>
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" id="username" formControlName="username" placeholder="Enter your username">
        <div *ngIf="loginForm.get('username')?.invalid && loginForm.get('username')?.touched" class="error-message">
          Username is required.
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" id="password" formControlName="password" placeholder="Enter your password">
        <div *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched" class="error-message">
          Password is required.
        </div>
      </div>
      <button type="submit" class="login-button" [disabled]="loginForm.invalid">
        <span [class.d-none]="!loggingIn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Login </button>
    </form>
  </div>
</div>

<app-alert></app-alert>